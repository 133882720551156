import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { RootState } from 'app/store/types';
import {
  ajaxCreateAdditionalReports,
  ajaxGetCustomerAccounts,
  ajaxGetCustomerReports,
  verificationConnectComplete,
} from 'app/actions/form/verification/actions';
import { Chip, Grid, LinearProgress } from '@material-ui/core';
import { getVerificationStatus, getVerificationUrl } from 'app/reducers/verification';
import { FieldNames } from 'app/models/fields/names';
import { StatusType } from 'app/actions/form/verification/types';
import { ScriptState, useExternalScript } from 'app/util/hooks';
import { closeFinicity, openFinicity } from 'app/components/Turbo/finicity-iframe';
import { getNextSection, goToSection } from '../../actions/form/actions';
import { ArrowDownward } from '@material-ui/icons';
import { getFormName } from 'app/routes/helpers';
import {
  HOME_EQUITY_TURBO_EMPLOYMENT_TYPE,
  PURCHASE_TURBO_EMPLOYMENT_TYPE,
  REFINANCE_TURBO_EMPLOYMENT_TYPE,
} from 'app/models/sections/constants';
import { FormName, YesOrNo } from '../../models/options/enums';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = DispatchProps & MappedProps;

const FinicityConnectBaseComponent = (props: Props) => {
  const {
    ajaxGetCustomerAccounts,
    ajaxCreateAdditionalReports,
    ajaxGetCustomerReports,
    formName,
    exitFinicity,
    verificationConnectComplete,
    onAllReportsCompleted,
    status,
    verificationUrl,
  } = props;

  const externalScript = 'https://connect2.finicity.com/assets/sdk/finicity-connect.min.js';
  const scriptState = useExternalScript(externalScript);

  const isReadyToConnect = status === StatusType.URL_GENERATED && scriptState === ScriptState.READY;

  React.useEffect(() => {
    if (isReadyToConnect) {
      openFinicity('#connect-container', verificationUrl, verificationConnectComplete, () => exitFinicity(formName), formName);

      // Resize iFrame
      const iFrame = document.querySelector(`iframe[id='finicityConnectIframe']`);
      iFrame['width'] = '100%';
      iFrame['height'] = '755px';

      return () => closeFinicity();
    }
  }, [isReadyToConnect, verificationUrl, formName, verificationConnectComplete, exitFinicity ]);

  React.useEffect(() => {
    if (status === StatusType.CONNECT_COMPLETE) {
      ajaxGetCustomerAccounts();
    }
    if (status === StatusType.REFRESH_ACCOUNTS_COMPLETE) {
      ajaxCreateAdditionalReports();
    }
    if (status === StatusType.CREATE_REPORTS_STARTED) {
      ajaxGetCustomerReports();
    }
    if (status === StatusType.CREATE_REPORTS_COMPLETE) {
      onAllReportsCompleted(formName);
    }
  }, [formName, status, ajaxGetCustomerAccounts, ajaxCreateAdditionalReports, ajaxGetCustomerReports, onAllReportsCompleted]);

  // renders Finicity iFrame
  if (isReadyToConnect) {
    return <div id='connect-container' style={{ width: '100%', height: 755 }} />;
  }
  // renders progress
  return <>
    <Grid container spacing={3} style={{ marginBottom: 15 }}>
      <Grid item xs={12}>
        {
          status < StatusType.CREATE_REPORTS_COMPLETE ?
            'Expected Time Remaining (under 5 minutes)' :
            'Progress Complete'
        }
      </Grid>
      <Grid item xs={12}>
        {
          status < StatusType.CREATE_REPORTS_COMPLETE ?
            <LinearProgress /> :
            <LinearProgress variant='determinate' value={100} />
        }
      </Grid>
    </Grid>
    <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2} style={{ marginBottom: 15 }}>
      <Grid item>
        <Chip
          label='Connecting to Financial Accounts'
          color={(status >= StatusType.CONNECT_COMPLETE ? 'primary' : 'default')}
          variant='outlined'
        />
      </Grid>
      <Grid item>
        <ArrowDownward />
      </Grid>
      <Grid item>
        <Chip
          label='Refreshing Financial Accounts'
          color={(status >= StatusType.REFRESH_ACCOUNTS_COMPLETE ? 'primary' : 'default')}
          variant='outlined'
        />
      </Grid>
      <Grid item>
        <ArrowDownward />
      </Grid>
      <Grid item>
        <Chip
          label='Generating Financial Reports'
          color={(status >= StatusType.CREATE_REPORTS_COMPLETE ? 'primary' : 'default')}
          variant='outlined'
        />
      </Grid>
    </Grid>
  </>;
};

const mapDispatchToProps = (dispatch) => ({
  exitFinicity                : (formName) => {
    dispatch(change(formName, FieldNames.hasExitedFinicity, YesOrNo.YES));
    let redirectLocation;
    switch (formName) {
      case FormName.HOME_EQUITY_TURBO:
        redirectLocation = HOME_EQUITY_TURBO_EMPLOYMENT_TYPE;
        break;
      case FormName.REFINANCE_TURBO:
        redirectLocation = REFINANCE_TURBO_EMPLOYMENT_TYPE;
        break;
      case FormName.PURCHASE_TURBO:
        redirectLocation = PURCHASE_TURBO_EMPLOYMENT_TYPE;
    }
    return dispatch(goToSection(redirectLocation));
  },
  verificationConnectComplete : () => {
    dispatch(verificationConnectComplete());
    return dispatch(getNextSection());
  },
  ajaxGetCustomerAccounts     : () => dispatch(ajaxGetCustomerAccounts()),
  ajaxCreateAdditionalReports : () => dispatch(ajaxCreateAdditionalReports()),
  ajaxGetCustomerReports      : () => dispatch(ajaxGetCustomerReports()),
  onAllReportsCompleted       : (formName) => {
    dispatch(change(formName, FieldNames.isFinancialVerificationComplete, true));
  },
});

const mapStateToProps = (state: RootState) => ({
  formName        : getFormName(state.router.location.pathname),
  verificationUrl : getVerificationUrl(state),
  status          : getVerificationStatus(state),
});

export const FinicityConnectComponent = connect(mapStateToProps, mapDispatchToProps)(FinicityConnectBaseComponent);
