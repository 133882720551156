import { parse } from 'query-string';
import { FieldNames } from 'app/models/fields/names';
import { storage } from 'app/util/storage';
import { toDateTime } from 'app/i18n/format';
import { getProduct, PRODUCT } from 'app/util/headers';
import i18next from 'i18next';
import { logger } from 'app/util/logger';

export interface ParsedQueryParams {
  loanOfficerId?: string;
  lng?          : string;
  loanPurpose?  : string;
  OpportunityId?: string;
  CreateDate?   : string;
  Time?         : string;
}

export interface SalesforceParams {
  opportunityId         : string;
  opportunityCreateDate?: string;
}

export const getLoanAmount = (formData) => {
  return formData[FieldNames.loanAmount]
    ? formData[FieldNames.loanAmount]
    : formData[FieldNames.homeValue] - formData[FieldNames.downDollar];
};

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
    window.location.host.includes(process.env.PORT || '3000'),
);

/**
 * Gets base url from location with an optional route passed in.
 *
 * Example: http://localhost:4000/resume
 *          https://lklecot.prequalexpress.com/resume
 * or
 * Example: https://flagstar2.lenderfulsolutions.com/v2/resume
 *
 * @param {string} [route='']
 * @returns {string}
 */
export const createBaseUrlFromLocation = (route: string = ''): string => {
  const product = getProduct();
  if (isLocalhost || product === PRODUCT.AUTO_PREQUAL ) {
    return `${window.location.origin}/${route}`;
  } else {
    return `${window.location.origin}/v2/${route}`;
  }
};

/**
 * Endpoints found in backend-services
 */
export enum Endpoints {
  AUTO_LOAN_RATES                = 'rates/consumer-loan/auto',
  AUTO_LOAN_LIMITS               = 'rates/consumer-loan/limits/auto',
  AUTO_LOAN_LIMITS_DTI           = 'rates/consumer-loan/debt-to-income/auto',
  AUTO_VALUATION_SEARCH          = 'valuation/auto/search',
  AUTO_VALUATION_DETAILS         = 'valuation/auto/details',
  AUTO_VALUATION_REPORT          = 'valuation/auto/report',
  AUDIT_EVENT                    = 'audit-event',
  CHECKLIST                      = 'file-generation/checklist',
  CHANGE_PASSWORD                = 'auth/password/change-password',
  CONFIG                         = 'client-management/config',
  CONTACT_US_EMAIL               = 'messaging/email',
  CONTACT_US_DATA                = 'messaging/contact',
  CREATE_INSURANCE_QUOTE         = 'insurance-quote',
  CREDIT_REPORT                  = 'vendor/credit-reporting-services',
  CREDIT_BOOSTER_RATES           = 'rates/consumer-loan/credit-booster',
  CREDIT_BOOSTER_LOAN_LIMITS     = 'rates/consumer-loan/limits/credit-booster',
  EMAIL                          = 'file-generation/rates',
  EQUITYRATES                    = 'home-equity/rates',
  HOME_EQUITY_ACCEPTED_LOCATION  = 'home-equity/accepted-location',
  HOME_EQUITY_LOAN_AMOUNT_LIMITS = 'home-equity/loan-amount-limits',
  EMAIL_CHECK                    = 'auth/email/email-check',
  EXPRESS_FORM                   = 'mortgage-applications/express',
  FINANCIAL_STATEMENT            = 'financial-statement',
  FINANCIAL_VERIFICATION         = 'verification/customer',
  FINANCIAL_VERIFICATION_TOKEN   = 'verification/token',
  FINANCIAL_ORDER                = 'verification/order',
  GENERATE_PREQUAL_LETTER          = 'file-generation/pdf',
  GET_PREQUAL_LETTER               = 'user-management/prequal-letter',
  LOANS                            = 'loans',
  LOAN_ESTIMATE                    = 'file-generation/loan-estimate',
  LOAN_OFFICER                     = 'client-management/loan-officer',
  LONGFORM                         = 'mortgage-applications/longform',
  OVERDRAFT_PROTECTION_RATES       = 'rates/consumer-loan/overdraft-protection',
  OVERDRAFT_PROTECTION_LOAN_LIMITS = 'rates/consumer-loan/limits/overdraft-protection',
  OVERDRAFT_PROTECTION_DTI         = 'rates/consumer-loan/debt-to-income/overdraft-protection',
  PARTIAL_APPS                     = 'mortgage-applications/partial',
  PERSONAL_LOAN_RATES              = 'rates/consumer-loan/personal',
  PERSONAL_LOAN_LIMITS             = 'rates/consumer-loan/limits/personal',
  PERSONAL_LOAN_DTI                = 'rates/consumer-loan/debt-to-income/personal',
  PREQUALRATES                     = 'rates/pre-qual',
  PROPERTY_VALUATION               = 'valuation/property',
  RATES                            = 'rates',
  REVERSE_MORTGAGE                 = 'rates/reverse-mortgage',
  REVERSE_SUMMARY                  = 'file-generation/reverse-summary',
  SHORTFORM                        = 'mortgage-applications/shortform',
  TRANSLATIONS                     = 'translations/locale',
  VALIDATE_ADDRESS                 = 'vendor/usps',
  VALIDATE_ZIP_CODE                = 'validation/zip',
}

/**
 * Checks the browser URL for query parameter loanPurpose
 * If loanPurpose is found its value is saved to sessionStorage
 *
 * @deprecated don't believe this is used
 * @param {ParsedQueryParams} [parsed={}]
 */
export const checkUrlForLoanPurpose = (parsed: ParsedQueryParams = {}): void => {
  if (parsed.loanPurpose) {
    logger.debug('found loanPurpose in query params, setting to sessionStorage', { parsed });
    setLoanPurposeToSessionStorage(parsed.loanPurpose);
  }
};

/**
 * Checks the browser URL for Salesforce query parameters and saves it to sessionStorage
 *
 * @param ParsedQueryParams parsed query parameters
 */
export const checkUrlForSalesforceParams = (parsed: ParsedQueryParams = {}): void => {
  if (parsed.OpportunityId) {
    logger.debug('Found OpportunityId in query params, setting value to sessionStorage', { parsed });
    let opportunityCreateDate = '';
    if (parsed.CreateDate && parsed.Time) {
      opportunityCreateDate = toDateTime(`${parsed.CreateDate} ${parsed.Time}`);
    }
    setSalesforceParams({
      opportunityId: parsed.OpportunityId || '',
      opportunityCreateDate,
    });
  }
};

export const setQueryParamsToLocalStorage = (): void => {
  const { search } = window.location;
  if (search) {
    const parsed = parse(search);
    checkUrlForLocalizationParam(parsed);
    checkUrlForLoanPurpose(parsed);
    checkUrlForSalesforceParams(parsed);
  }
};

/**
 * Checks if the url has a localization param
 *
 * @example
 * /purchase?lng=en
 *
 * @param {ParsedQueryParams} [parsed={}]
 */
export const checkUrlForLocalizationParam = (parsed: ParsedQueryParams = {}): void => {
  if (parsed.lng) {
    setLocaleToLocalStorage(parsed.lng);
  }
};

/**
 * Sets a locale to the localStorage key used by i18next
 *
 * @param {string} lng
 */
export const setLocaleToLocalStorage = (lng: string): void => {
  logger.debug('Setting i18nextLng in localStorage to: ', lng);
  storage.local.setItem('i18nextLng', lng);
};

/**
 * Get locale key from localStorage
 *
 * @return {string} browser locale as string (i.e. en-US or es-US)
 */
export const getLocale = (): string => {
  return i18next.languages[0] || 'en';
};

/**
 * Sets a value to the loanPurpose key in sessionStorage
 *
 * @deprecated don't believe this is used
 * @param {string} loanPurpose
 */
export const setLoanPurposeToSessionStorage = (loanPurpose: string): void => {
  logger.debug('Setting loanPurpose in sessionStorage to: ', loanPurpose);
  storage.session.setItem('loanPurpose', loanPurpose);
};

/**
 * @deprecated don't believe this is used
 * @returns
 */
export const removeLoanPurposeFromSessionStorage = () => storage.session.removeItem('loanPurpose');

/**
 * Get Salesforce parameters from session storage if they exist, otherwise return nothing.
 *
 * @deprecated don't believe this is used
 * @returns Salesforce Parameters | {}
 */
export const getSalesforceParams = (): SalesforceParams | {} => {
  let result = {};
  try {
    const salesforceParams = storage.session.getItem('salesforceParams');
    if (salesforceParams) {
      const parsed: SalesforceParams = JSON.parse(salesforceParams);
      result = parsed;
    }
  } catch (error) {
    logger.error('Failed to parse Salesforce Parameters', { error });
  } finally {
    return result;
  }
};

/**
 * Sets a value to the `salesforceParams` key in sessionStorage
 *
 * @deprecated don't believe this is used
 * @param SalesforceParams Salesforce parameters
 */
export const setSalesforceParams = (salesforceParams?: SalesforceParams): void => {
  if (salesforceParams) {
    try {
      logger.debug('Setting salesforceParams in sessionStorage to', { salesforceParams });
      storage.session.setItem('salesforceParams', JSON.stringify(salesforceParams));
    } catch (error) {
      logger.error('Failed to set salesforceParams', { error });
    }
  }
};
