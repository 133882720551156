import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { hasCoBorrower, isExcludingSsnQuestions, isUsingReverseToPurchase, LoanOfficerCondition } from 'app/models/fields/conditionals';
import {
  REVERSE_ADVISOR_STOP_GATE,
  REVERSE_BORROWER_QUESTIONS,
  REVERSE_CITIZEN_AND_MARRIAGE,
  REVERSE_COBORROWER_CITIZEN_AND_MARRIAGE,
  REVERSE_COBORROWER_PERSONAL_INFORMATION,
  REVERSE_COBORROWER_QUESTIONS,
  REVERSE_CONFIRMATION,
  REVERSE_CURRENT_RESIDENCE,
  REVERSE_INCOME_AND_ASSETS,
  REVERSE_LOAN_GOAL,
  REVERSE_MORTGAGE_INFO,
  REVERSE_PERSONAL_INFO,
  REVERSE_PROPERTY_INFO,
  REVERSE_PROPERTY_TAX_INS_FEES,
  REVERSE_PROPERTY_TITLE_INFO,
  REVERSE_PROPERTY_TYPE_AND_USE,
  REVERSE_PURCHASE_PROPERTY_INFO,
  REVERSE_SSN,
} from './constants';
import { reverseQuestionsByName } from '../questions/reverse';
import { getReverseDeclarationQuestions } from '../questions/declarations';
import { BorrowerType } from '../options/enums';
import { FormParagraphs } from 'app/models/paragraphs';
import { ReverseConfirmation } from 'app/components/Reverse/ReverseConfirmation';
import {
  handleAddressValidationAndReverseShortFormSubmit,
  handleReverseFormSubmit,
  handleReverseShortFormSubmit,
} from 'app/actions/form/reverse-mortgage/actions';
import { ajaxValidateBorrowerResidence } from 'app/actions/form/address-validation/actions';
import { ReverseAdvisorStopGate } from 'app/components/Reverse/ReverseAdvisorStopGate';
import { AuthorizeSsnFullPull } from 'app/components/FormAgreements/AuthorizeSsnFullPull';

export const getReverseSectionById = (id: number): Section => {
  return APPLICATION_REVERSE_SECTIONS.find((section) => section.id === id) as Section;
};

const APPLICATION_REVERSE_SECTIONS: Section[] = [
  {
    id: REVERSE_LOAN_GOAL,
    questions: reverseQuestionsByName([FieldNames.reverseMortgageLoanPurpose]),
  },
  {
    id: REVERSE_PERSONAL_INFO,
    title: 'Enter your personal information',
    paragraph : FormParagraphs.reverseMortgageVerbiage,
    questions: reverseQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
      FieldNames.age,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isBorrowerTooYoung],
    },
  },
  {
    id: REVERSE_COBORROWER_PERSONAL_INFORMATION,
    questions: reverseQuestionsByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerDOB,
      FieldNames.coBorrowerAge,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerPhone,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isCoBorrowerTooYoung],
    },
  },
  {
    id: REVERSE_PURCHASE_PROPERTY_INFO,
    title: 'Tell us about your new home purchase',
    questions: reverseQuestionsByName([
      FieldNames.purchasePrice,
      FieldNames.propertyType,
      FieldNames.hasSelectedNewHome,
      FieldNames.propertyStreet,
      FieldNames.propertyStreet2,
      FieldNames.propertyCity,
      FieldNames.propertyState,
      FieldNames.propertyZip,
    ]),
    showIf: [isUsingReverseToPurchase],
  },
  {
    id: REVERSE_PROPERTY_INFO,
    questions: reverseQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
    ]),
    title : 'What is the property for your Reverse Mortgage?',
    showIf : [(values) => !isUsingReverseToPurchase(values)],
    submitAction: ajaxValidateBorrowerResidence,
  },
  {
    id: REVERSE_MORTGAGE_INFO,
    questions: reverseQuestionsByName([
      FieldNames.homeValue,
      FieldNames.propertyHasMortgage,
      FieldNames.mortgageBalance,
    ]),
    showIf: [(values) => !isUsingReverseToPurchase(values)],
    submitAction: handleReverseShortFormSubmit,
  },
  {
    id: REVERSE_CURRENT_RESIDENCE,
    title: 'Tell us about your current residence',
    questions: reverseQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
    ]),
    showIf: [isUsingReverseToPurchase],
    submitAction: handleAddressValidationAndReverseShortFormSubmit,
  },
  {
    id: REVERSE_CITIZEN_AND_MARRIAGE,
    title: '',
    questions: reverseQuestionsByName([
      FieldNames.citizenshipType,
      FieldNames.maritalStatus,
    ]),
  },
  {
    id: REVERSE_COBORROWER_CITIZEN_AND_MARRIAGE,
    title: '',
    questions: reverseQuestionsByName([
      FieldNames.coBorrowerCitizenshipType,
      FieldNames.coBorrowerMaritalStatus,
      FieldNames.coBorrowerOnTitle,
    ]),
    showIf  : [hasCoBorrower],
  },
  {
    id: REVERSE_PROPERTY_TYPE_AND_USE,
    title: 'Tell us more about your property',
    questions: reverseQuestionsByName([
      FieldNames.propertyType,
      FieldNames.propertyUsage,
    ]),
    showIf: [(values) => !isUsingReverseToPurchase(values)],
  },
  {
    id: REVERSE_PROPERTY_TITLE_INFO,
    questions: reverseQuestionsByName([
      FieldNames.propertyTitleHeld,
    ]),
    showIf: [(values) => !isUsingReverseToPurchase(values)],
  },
  {
    id: REVERSE_PROPERTY_TAX_INS_FEES,
    title: 'Property tax, insurance, and fee information',
    questions: reverseQuestionsByName([
      FieldNames.livingYearTaxes,
      FieldNames.livingYearInsure,
      FieldNames.livingYearAssociate,
      FieldNames.hasLatePropertyCharges,
    ]),
    showIf: [(values) => !isUsingReverseToPurchase(values)],
  },
  {
    id: REVERSE_INCOME_AND_ASSETS,
    questions: reverseQuestionsByName([
      FieldNames.incomeMonthly,
      FieldNames.assetTotalValue,
      FieldNames.hasAddlPropertiesYN,
      FieldNames.addlProperties,
      FieldNames.coBorrowerIncomeMonthly,
      FieldNames.coBorrowerAssetTotalValue,
    ]),
  },
  {
    id        : REVERSE_BORROWER_QUESTIONS,
    title     : 'Borrower Questions',
    questions : getReverseDeclarationQuestions(BorrowerType.PRIMARY),
    submitIf  : [(values) => !hasCoBorrower(values) && isExcludingSsnQuestions(values)],
    submitAction : handleReverseFormSubmit,
  },
  {
    id        : REVERSE_COBORROWER_QUESTIONS,
    title     : 'Co-Borrower Questions',
    questions : getReverseDeclarationQuestions(BorrowerType.COBORROWER),
    showIf    : [hasCoBorrower],
    submitIf  : [isExcludingSsnQuestions],
    submitAction : handleReverseFormSubmit,
  },
  {
    id           : REVERSE_SSN,
    questions    : reverseQuestionsByName([FieldNames.ssnQuestionRequirement]),
    showIf       : [(values) => !isExcludingSsnQuestions(values)],
    submitAction : handleReverseFormSubmit,
    agreement    : <AuthorizeSsnFullPull />,
    submitText   : 'Submit',
  },
  {
    id: REVERSE_CONFIRMATION,
    ifNoQuestions: ReverseConfirmation,
    hideProgressBar: true,
  },
  {
    id: REVERSE_ADVISOR_STOP_GATE,
    ifNoQuestions: ReverseAdvisorStopGate,
    hideProgressBar: true,
  },
];
