import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { RootState } from 'app/store/types';
import { ajaxOrderAndBridgeToken } from 'app/actions/form/verification/actions';
import { FieldNames } from 'app/models/fields/names';
import { goToSection } from '../../actions/form/actions';
import { getVerificationOrder, getVerificationUser } from 'app/reducers/verification';
import { getFormName } from 'app/routes/helpers';
import {
  HOME_EQUITY_TURBO_EMPLOYMENT_TYPE,
  PURCHASE_TURBO_EMPLOYMENT_TYPE,
  REFINANCE_TURBO_EMPLOYMENT_TYPE,
} from 'app/models/sections/constants';
import { FormName, YesOrNo } from '../../models/options/enums';
import { useState } from 'react';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = DispatchProps & MappedProps;

const TruvConnectBaseComponent = (props: Props) => {

  const TruvBridge = lazy(() => import('@truv/react'));

  const [isOpened, setOpened] = useState(false);

  const {
    ajaxCreateOrderToken,
    exitTruv,
    formName,
    order,
  } = props;

  React.useEffect(() => {
    const createOrderAndBridgeToken = async () => {
      return await ajaxCreateOrderToken(formName);
    };
    createOrderAndBridgeToken();
  }, [ajaxCreateOrderToken, formName]);

  React.useEffect(() => {
    if (order && !isOpened) {
      setOpened(true);
    }
  }, [isOpened, order]);

  return (
    <>
      <TruvBridge
        bridgeToken = {order?.bridge_token}
        isOrder     = {true}
        onClose     = {() => {
          setOpened(false);
          exitTruv(formName);
        }}
        onSuccess = {() => {
          console.debug('----  onSuccess');
        }}
        isOpened = {isOpened}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ajaxCreateOrderToken: async (formName) => {
    return dispatch(await ajaxOrderAndBridgeToken());
  },
  exitTruv : (formName) => {
    dispatch(change(formName, FieldNames.hasExitedTruv, YesOrNo.YES));
    dispatch(change(formName, FieldNames.isFinancialVerificationComplete, true));

    let redirectLocation;
    switch (formName) {
      case FormName.HOME_EQUITY_TURBO:
        redirectLocation = HOME_EQUITY_TURBO_EMPLOYMENT_TYPE;
        break;
      case FormName.REFINANCE_TURBO:
        redirectLocation = REFINANCE_TURBO_EMPLOYMENT_TYPE;
        break;
      case FormName.PURCHASE_TURBO:
        redirectLocation = PURCHASE_TURBO_EMPLOYMENT_TYPE;
    }
    return dispatch(goToSection(redirectLocation));
  },
});

const mapStateToProps = (state: RootState) => ({
  formName: getFormName(state.router.location.pathname),
  order   : getVerificationOrder(state),
  user    : getVerificationUser(state) || undefined,
});

export const TruvConnectComponent = connect(mapStateToProps, mapDispatchToProps)(TruvConnectBaseComponent);
