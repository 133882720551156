import React, { useEffect } from 'react';
import { FieldArray } from 'redux-form';
import { FieldNames } from 'app/models/fields/names';
import { Button } from 'app/components/Button';
import { FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizeLabel } from 'app/i18n/helpers';
import { Address } from 'app/components/FormFields/Address';
import { State } from 'app/components/FormFields/State';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { Currency } from 'app/components/FormFields/Currency';

const initialValue = {
  [FieldNames.propertyStreet]                  : '',
  [FieldNames.propertyStreet2]                 : '',
  [FieldNames.propertyCity]                    : '',
  [FieldNames.propertyState]                   : '',
  [FieldNames.propertyZip]                     : '',
  [FieldNames.propertyValue]                   : '',
};

const RenderPropertiesComponent = ({ ...props }) => {
  const { fields, name, t } = props;

  const propertyStreetLabel                = localizeLabel(`${name}.${FieldNames.propertyStreet}`, t, 'Street Address');
  const propertyStreet2Label               = localizeLabel(`${name}.${FieldNames.propertyStreet2}`, t, 'Street Address 2');
  const propertyCityLabel                  = localizeLabel(`${name}.${FieldNames.propertyCity}`, t, 'City');
  const propertyStateLabel                 = localizeLabel(`${name}.${FieldNames.propertyState}`, t, 'State');
  const propertyZipLabel                   = localizeLabel(`${name}.${FieldNames.propertyZip}`, t, 'Zip Code');
  const propertyValueLabel                 = localizeLabel(`${name}.${FieldNames.propertyValue}`, t, 'Present Value');

  const renderFields = () =>
    fields.map((field, index) => {
      return (
        <div key = {`${field}-${index}`} style={{ paddingBottom: 20 }}>
          <Address
            label = {propertyStreetLabel}
            isOptional = {true}
            name  = {`${field}.${FieldNames.propertyStreet}`}
          />
          <Address
            label      = {propertyStreet2Label}
            isOptional = {true}
            name       = {`${field}.${FieldNames.propertyStreet2}`}
          />
          <Address
            label = {propertyCityLabel}
            name  = {`${field}.${FieldNames.propertyCity}`}
          />
          <State
            label = {propertyStateLabel}
            name  = {`${field}.${FieldNames.propertyState}`}
          />
          <ZipCode
            label = {propertyZipLabel}
            name  = {`${field}.${FieldNames.propertyZip}`}
          />
          <Currency
            label = {propertyValueLabel}
            name  = {`${field}.${FieldNames.propertyValue}`}
          />

          {fields.length > 1 && (
            <Button
              disableRipple
              color   = 'primary'
              classes = {{
                root: 'link',
                text: 'remove',
              }}
              onClick = {() => fields.remove(index)}
            >
              {t(`question.${name}.button.remove`, { defaultValue: 'Remove' })}
            </Button>
          )}
        </div>
      );
    });

  useEffect(() => {
    if (fields.length === 0) {
      fields.push(initialValue);
    }
  }, [fields]);

  return (
    <div>
      {renderFields()}
      <Button
        color    = 'primary'
        disabled = {props.meta.invalid}
        variant  = 'outlined'
        classes  = {{ root: 'add' }}
        onClick  = {() => fields.push(initialValue)}
      >
        {t(`question.${name}.button.add`, { defaultValue: '+ Add Property' })}
      </Button>
    </div>
  );
};

export const AdditionalPropertiesAlternative = ({ ...props }) => {
  const { name, values } = props;
  const { t }            = useTranslation();
  return (
    <FormControl fullWidth>
      <FieldArray
        component = {RenderPropertiesComponent}
        name      = {name}
        props     = {{ name, values, t }}
      />
    </FormControl>
  );
};
