import { FieldNames } from 'app/models/fields/names';
import {
  ConstructionHomeType,
  ConstructionRoofingType,
  EscrowUsage,
  FormName,
  LivingSituation,
  NumberOfStories,
  YesOrNo,
} from 'app/models/options/enums';
import { LoanPurpose } from 'app/api/rates/types';

/**
 * Initial form values for declarations
 */
export const declarationInitialValue = {
  [FieldNames.ethnicity]      : [],
  [FieldNames.race]           : [],
  [FieldNames.sex]            : [],
  [FieldNames.hasCitizenship] : true,
  [FieldNames.hasBankrupt]    : false,
  [FieldNames.hasForeclosed]  : false,
  [FieldNames.hasJudgements]  : false,
};

/**
 * Initial form values for bank assets on the long form.
 */
export const assetInitialValue = {
  [FieldNames.accountType]   : '',
  [FieldNames.accountBalance]: 0,
};

/**
 * Initial form values for owners on the commercial forms.
 */
export const commercialOwnerInitialValue = {
  [FieldNames.ownerFirstName]   : '',
  [FieldNames.ownerLastName]    : '',
  [FieldNames.ownerTitleHeld]   : '',
  [FieldNames.race]             : [],
  [FieldNames.ethnicity]        : [],
  [FieldNames.sex]              : [],
};

/**
 * Initial form values for key management on the commercial forms.
 */
export const commercialManagementInitialValue = {
  [FieldNames.managementFirstName]   : '',
  [FieldNames.managementLastName]    : '',
  [FieldNames.managementTitleHeld]   : '',
};

/**
 * Initial form values for vehicle list on the commercial vehicle form.
 */
export const commercialVehicleInitialValue = {
  [FieldNames.vehicleType]    : '',
  [FieldNames.vehicleYear]    : '',
  [FieldNames.vehicleMake]    : '',
  [FieldNames.vehicleCount]   : 1,
};

/**
 * Initial form values for income on the long form.
 */
export const incomeInitialValue = {
  [FieldNames.incomeSource] : '',
  [FieldNames.incomeMonthly]: 0,
};

/**
 * Initial form values for purchase short form.
 */
export const purchaseSFInitialValues = {
  [FieldNames.buyingProcess]: '',
  [FieldNames.downDollar]   : 0,
  [FieldNames.downPercent]  : 0,
  [FieldNames.email]        : '',
  [FieldNames.escrowUsage]  : '',
  [FieldNames.creditScore]  : '',
  [FieldNames.lastName]     : '',
  [FieldNames.loanPurpose]  : LoanPurpose.PURCHASE,
  [FieldNames.phone]        : '',
  [FieldNames.propertyType] : '',
  [FieldNames.propertyUsage]: '',
  [FieldNames.propertyZip]  : '',
};

/**
 * Initial form values for refinance short form.
 */
export const refinanceSFInitialValues = {
  [FieldNames.email]        : '',
  [FieldNames.escrowUsage]  : '',
  [FieldNames.creditScore]  : '',
  [FieldNames.firstName]    : '',
  [FieldNames.lastName]     : '',
  [FieldNames.loanPurpose]  : '',
  [FieldNames.loanAmount]   : 0,
  [FieldNames.phone]        : '',
  [FieldNames.propertyType] : '',
  [FieldNames.propertyUsage]: '',
  [FieldNames.propertyZip]  : '',
};

/**
 * Initial values for purchase long form.
 */
export const purchaseLFInitialValues = {
  [FieldNames.assets]           : [assetInitialValue],
  [FieldNames.downDollar]       : 0,
  [FieldNames.downPercent]      : 0,
  [FieldNames.coBorrowerOnTitle]: YesOrNo.NO,
  [FieldNames.listingPrice]     : 0,
  [FieldNames.loanPurpose]      : LoanPurpose.PURCHASE,
  [FieldNames.mortgageBalance]  : 0,
  [FieldNames.numOfDependent]   : 0,
  [FieldNames.decBorrower]      : declarationInitialValue,
  [FieldNames.decCoBorrower]    : declarationInitialValue,
  [FieldNames.yearTaxes]        : 0,
  [FieldNames.yearInsure]       : 0,
  [FieldNames.yearAssociate]    : 0,
};

/**
 * Initial values for land long form.
 */
export const landLFInitialValues = {
  [FieldNames.assets]           : [assetInitialValue],
  [FieldNames.downDollar]       : 0,
  [FieldNames.downPercent]      : 0,
  [FieldNames.coBorrowerOnTitle]: YesOrNo.NO,
  [FieldNames.listingPrice]     : 0,
  [FieldNames.loanPurpose]      : LoanPurpose.LAND_PURCHASE,
  [FieldNames.mortgageBalance]  : 0,
  [FieldNames.numOfDependent]   : 0,
  [FieldNames.decBorrower]      : declarationInitialValue,
  [FieldNames.decCoBorrower]    : declarationInitialValue,
  [FieldNames.yearTaxes]        : 0,
};

/**
 * Initial values for commercial purchase form.
 */
export const commercialPurchaseInitialValues = {
  [FieldNames.assets]                   : [assetInitialValue],
  [FieldNames.commercialManagementList] : [commercialManagementInitialValue],
  [FieldNames.commercialOwnerList]      : [commercialOwnerInitialValue],
  [FieldNames.downDollar]               : 0,
  [FieldNames.downPercent]              : 0,
  [FieldNames.loanPurpose]              : LoanPurpose.PURCHASE,
  [FieldNames.yearTaxes]                : 0,
  [FieldNames.yearInsure]               : 0,
  [FieldNames.decBorrower]              : {
    ...declarationInitialValue,
    [FieldNames.hasCitizenship]      : undefined,
    dec_commercial_bankrupt_flag     : 'No',
    dec_commercial_credit_flag       : 'No',
    dec_commercial_debt_flag         : 'No',
    dec_commercial_legal_action_flag : 'No',
    dec_commercial_tax_due_flag      : 'No',
  },
};

/**
 * Initial values for commercial refinance form.
 */
export const commercialRefinanceInitialValues = {
  [FieldNames.commercialManagementList] : [commercialManagementInitialValue],
  [FieldNames.commercialOwnerList]      : [commercialOwnerInitialValue],
  [FieldNames.loanAmount]       : 0,
  [FieldNames.decBorrower]              : {
    ...declarationInitialValue,
    [FieldNames.hasCitizenship]      : undefined,
    dec_commercial_bankrupt_flag     : 'No',
    dec_commercial_credit_flag       : 'No',
    dec_commercial_debt_flag         : 'No',
    dec_commercial_legal_action_flag : 'No',
    dec_commercial_tax_due_flag      : 'No',
  },
};

/**
 * Initial values for commercial equipment form.
 */
export const commercialEquipmentInitialValues = {
  [FieldNames.commercialManagementList] : [commercialManagementInitialValue],
  [FieldNames.commercialOwnerList]      : [commercialOwnerInitialValue],
  [FieldNames.loanPurpose]              : LoanPurpose.PURCHASE,
  [FieldNames.decBorrower]              : {
    ...declarationInitialValue,
    [FieldNames.hasCitizenship]      : undefined,
    dec_commercial_bankrupt_flag     : 'No',
    dec_commercial_credit_flag       : 'No',
    dec_commercial_debt_flag         : 'No',
    dec_commercial_legal_action_flag : 'No',
    dec_commercial_tax_due_flag      : 'No',
  },
};

/**
 * Initial values for commercial vehicle form.
 */
export const commercialVehicleInitialValues = {
  [FieldNames.commercialManagementList] : [commercialManagementInitialValue],
  [FieldNames.commercialOwnerList]      : [commercialOwnerInitialValue],
  [FieldNames.commercialVehicleList]    : [commercialVehicleInitialValue],
  [FieldNames.decBorrower]              : {
    ...declarationInitialValue,
    [FieldNames.hasCitizenship]      : undefined,
    dec_commercial_bankrupt_flag     : 'No',
    dec_commercial_credit_flag       : 'No',
    dec_commercial_debt_flag         : 'No',
    dec_commercial_legal_action_flag : 'No',
    dec_commercial_tax_due_flag      : 'No',
  },
};

/**
 * Initial values for commercial bridge loan form.
 */
export const commercialBridgeLoanInitialValues = {
  [FieldNames.commercialManagementList] : [commercialManagementInitialValue],
  [FieldNames.commercialOwnerList]      : [commercialOwnerInitialValue],
  [FieldNames.decBorrower]              : {
    ...declarationInitialValue,
    [FieldNames.hasCitizenship]      : undefined,
    dec_commercial_bankrupt_flag     : 'No',
    dec_commercial_credit_flag       : 'No',
    dec_commercial_debt_flag         : 'No',
    dec_commercial_legal_action_flag : 'No',
    dec_commercial_tax_due_flag      : 'No',
  },
};

/**
 * Initial values for refinance long form.
 */
export const refinanceLFInitialValues = {
  [FieldNames.assets]           : [assetInitialValue],
  [FieldNames.coBorrowerOnTitle]: YesOrNo.NO,
  [FieldNames.loanAmount]       : 0,
  [FieldNames.numOfDependent]   : 0,
  [FieldNames.livingRentOrOwn]  : LivingSituation.OWN,
  [FieldNames.decBorrower]      : declarationInitialValue,
  [FieldNames.decCoBorrower]    : declarationInitialValue,
};

/**
 * Initial values for personal loan form.
 */
export const personalLoanInitialValues = {
  [FieldNames.loanPurpose]   : LoanPurpose.PERSONAL_LOAN,
  [FieldNames.decBorrower]   : declarationInitialValue,
  [FieldNames.decCoBorrower] : declarationInitialValue,
  [FieldNames.minLoanAmount] : 0,
  [FieldNames.maxLoanAmount] : 200000,
};

/**
 * Initial values for overdraft protection form.
 */
export const overdraftProtectionInitialValues = {
  [FieldNames.loanPurpose]   : LoanPurpose.OVERDRAFT_PROTECTION,
  [FieldNames.decBorrower]   : declarationInitialValue,
  [FieldNames.decCoBorrower] : declarationInitialValue,
  [FieldNames.minLoanAmount] : 0,
  [FieldNames.maxLoanAmount] : 200000,
};

/**
 * Initial form values for home equity short form.
 */
export const homeEquitySFInitialValues = {
  [FieldNames.livingRentOrOwn]: LivingSituation.OWN,
  [FieldNames.loanAmount]     : 0,
  [FieldNames.loanPurpose]    : 'HELOC',
  [FieldNames.mortgageBalance]: 0,
  [FieldNames.propertyType]   : '',
  [FieldNames.propertyUsage]  : '',
};

/**
 * Initial values for home equity long form.
 */
export const homeEquityLFInitialValues = {
  [FieldNames.decBorrower]    : declarationInitialValue,
  [FieldNames.decCoBorrower]  : declarationInitialValue,
  [FieldNames.livingRentOrOwn]: LivingSituation.OWN,
  [FieldNames.loanAmount]     : 0,
  [FieldNames.loanPurpose]    : 'HELOC',
  [FieldNames.mortgageBalance]: 0,
  [FieldNames.numOfDependent] : 0,
};

/**
 * Initial values for auto prequal long form.
 */
export const autoPrequalLFInitialValues = {
  [FieldNames.downDollar]      : 0,
  [FieldNames.downPercent]     : 0,
  [FieldNames.escrowUsage]     : EscrowUsage.YES,
  [FieldNames.loanPurpose]     : LoanPurpose.PURCHASE,
  [FieldNames.yearTaxes]       : 0,
  [FieldNames.yearInsure]      : 0,
  [FieldNames.yearAssociate]   : 0,
  /* This makes the additional income show by default */
  [FieldNames.additionalIncome]: [incomeInitialValue],
  /* Declarations are only gathered for primary borrower on auto prequal */
  [FieldNames.decBorrower]     : declarationInitialValue,
  [FieldNames.decCoBorrower]   : {},
};

/**
 * Initial values for auto prequal renew long form.
 */
export const autoPrequalRenewLFInitialValues = {
  /* This makes the additional income show by default */
  [FieldNames.additionalIncome]: [incomeInitialValue],
  /* Declarations are only gathered for primary borrower on auto prequal */
  [FieldNames.decBorrower]     : declarationInitialValue,
};

/**
 * Initial values for auto loan long form. Needs real values
 */
export const autoLoanInitialValues = {
  [FieldNames.decBorrower]   : declarationInitialValue,
  [FieldNames.decCoBorrower] : declarationInitialValue,
  [FieldNames.downDollar]    : 0,
  [FieldNames.tradeInValue]  : 0,
  [FieldNames.minLoanAmount] : 0,
  [FieldNames.maxLoanAmount] : 200000,
};

export const businessLoanInitialValues = {
};

export const creditBoosterInitialValues = {
  [FieldNames.decBorrower]     : declarationInitialValue,
};

export const creditcardInitialValues = {
  [FieldNames.decBorrower]     : declarationInitialValue,
  [FieldNames.decCoBorrower]   : declarationInitialValue,
  [FieldNames.decRelative]     : declarationInitialValue,
};

export const ovLoanInitialValues = {
  [FieldNames.decBorrower]     : declarationInitialValue,
  [FieldNames.decCoBorrower]   : declarationInitialValue,
};

export const homeEquityTurboInitialValues = {
  [FieldNames.decBorrower]: {
    ...declarationInitialValue,
    [FieldNames.hasOccupyPrimary]: true,
  },
  [FieldNames.decCoBorrower]: {
    ...declarationInitialValue,
    [FieldNames.hasOccupyPrimary]: true,
  },
  [FieldNames.mortgageBalance]: 0,
};

export const homeEquityRatesInitialValues = {};

export const constructionLFInitialValues = {
  [FieldNames.assets]               : [assetInitialValue],
  [FieldNames.coBorrowerOnTitle]    : YesOrNo.NO,
  [FieldNames.loanPurpose]          : LoanPurpose.CONSTRUCTION,
  [FieldNames.numOfDependent]       : 0,
  [FieldNames.decBorrower]          : declarationInitialValue,
  [FieldNames.decCoBorrower]        : declarationInitialValue,
};

export const reverseInitialValues = {
  [FieldNames.expectedLumpSum]       : 0,
  [FieldNames.loanPurpose]           : LoanPurpose.REVERSE,
  [FieldNames.loanTerm]              : 20,
  [FieldNames.numOfDependent]        : 0,
  [FieldNames.decBorrower]           : declarationInitialValue,
  [FieldNames.decCoBorrower]         : declarationInitialValue,
  [FieldNames.hasReverseSummaryInfo] : YesOrNo.NO,
};

export const insuranceQuoteInitialValues = {
  [FieldNames.homeStories] : NumberOfStories.ONE,
  [FieldNames.constructionHomeType] : ConstructionHomeType.FRAME,
  [FieldNames.constructionRoofingType] : ConstructionRoofingType.COMPOSITION_SHINGLE,
};

export const returnInitialValues = ( form: FormName) => {
  switch (form) {
    case FormName.AUTOLOAN:
      return autoLoanInitialValues;
    case FormName.COMMERCIALBRIDGELOAN:
      return commercialBridgeLoanInitialValues;
    case FormName.COMMERCIALEQUIPMENT:
      return commercialEquipmentInitialValues;
    case FormName.COMMERCIALPURCHASE:
      return commercialPurchaseInitialValues;
    case FormName.COMMERCIALREFINANCE:
      return commercialRefinanceInitialValues;
    case FormName.COMMERCIALVEHICLE:
      return commercialVehicleInitialValues;
    case FormName.CONSTRUCTION:
      return constructionLFInitialValues;
    case FormName.CREDITBOOSTER:
      return creditBoosterInitialValues;
    case FormName.CREDITCARD:
      return creditcardInitialValues;
    case FormName.FINANCIALSTATEMENT:
      return financialStatementInitialValues;
    case FormName.HOMEEQUITY:
      return homeEquitySFInitialValues;
    case FormName.HOMEEQUITYLONGFORM:
      return homeEquityLFInitialValues;
    case FormName.HOME_EQUITY_TURBO:
      return homeEquityTurboInitialValues;
    case FormName.SBLINEOFCREDIT:
      return sbLineOfCreditInitialValues;
    case FormName.SBTERM:
      return sbTermInitialValues;
    case FormName.REFINANCE_TURBO:
      return refinanceTurboInitialValues;
    default:
      return {};
  }
};

export const cashInitialValue = {
  [FieldNames.financialStatementFinancialInstitution] : '',
  [FieldNames.financialStatementAccountType]          : '',
  [FieldNames.financialStatementCurrentBalance]       : 0,
  [FieldNames.financialStatementJointAccount]         : false,
};

export const lifeInsuranceInitialValue = {
  [FieldNames.financialStatementCompany]      : '',
  [FieldNames.financialStatementBeneficiary]  : '',
  [FieldNames.financialStatementFaceValue]    : 0,
  [FieldNames.financialStatementCashValue]    : 0,
  [FieldNames.financialStatementJointAccount] : false,
};

export const stocksInitialValue = {
  [FieldNames.financialStatementMarketValue]  : 0,
  [FieldNames.financialStatementSecurityName] : '',
  [FieldNames.financialStatementNumOfShares]  : 0,
  [FieldNames.financialStatementJointAccount] : false,
};

export const retirementAccountsInitialValue = {
  [FieldNames.financialStatementFinancialInstitution] : '',
  [FieldNames.financialStatementRetirementType]       : '',
  [FieldNames.financialStatementMarketValue]          : 0,
  [FieldNames.financialStatementJointAccount]         : false,
};

export const notesInitialValue = {
  [FieldNames.financialStatementOwingParty]     : '',
  [FieldNames.financialStatementBalanceOwed]    : 0,
  [FieldNames.financialStatementMonthlyPayment] : 0,
  [FieldNames.financialStatementMaturityDate]   : '',
  [FieldNames.financialStatementNotePurpose]    : '',
  [FieldNames.financialStatementJointAccount]   : false,
};

export const personalPropertyInitialValue = {
  [FieldNames.financialStatementPersonalType] : '',
  [FieldNames.financialStatementMarketValue]  : 0,
  [FieldNames.financialStatementDescription]  : '',
  [FieldNames.financialStatementJointAccount] : false,
};

export const realEstateInitialValue = {
  [FieldNames.financialStatementLocation]        : '',
  [FieldNames.financialStatementMarketValue]     : 0,
  [FieldNames.financialStatementPurchaseDate]    : '',
  [FieldNames.financialStatementDescription]     : '',
  [FieldNames.financialStatementJointAccount]    : false,
};

export const creditCardsInitialValue = {
  [FieldNames.financialStatementCreditor]       : '',
  [FieldNames.financialStatementCurrentBalance] : 0,
  [FieldNames.financialStatementMonthlyPayment] : 0,
  [FieldNames.financialStatementJointAccount]   : false,
};

export const mortgagesInitialValue = {
  [FieldNames.financialStatementCreditor]       : '',
  [FieldNames.financialStatementCurrentBalance] : 0,
  [FieldNames.financialStatementMonthlyPayment] : 0,
  [FieldNames.financialStatementMaturityDate]   : '',
  [FieldNames.financialStatementLocation]       : '',
  [FieldNames.financialStatementJointAccount]   : false,
};

export const loansInitialValue = {
  [FieldNames.financialStatementCreditor]       : '',
  [FieldNames.financialStatementCurrentBalance] : 0,
  [FieldNames.financialStatementMonthlyPayment] : 0,
  [FieldNames.financialStatementMaturityDate]   : '',
  [FieldNames.financialStatementDescription]    : '',
  [FieldNames.financialStatementSecuredBy]      : '',
  [FieldNames.financialStatementJointAccount]   : false,
};

export const taxesInitialValue = {
  [FieldNames.financialStatementTaxType]      : '',
  [FieldNames.financialStatementAmountOwed]   : 0,
  [FieldNames.financialStatementDescription]  : '',
  [FieldNames.financialStatementJointAccount] : false,
};

export const financialStatementInitialValues = {
  [FieldNames.decBorrower]                          : declarationInitialValue,
  [FieldNames.financialStatementCashAccount]        : [cashInitialValue],
  [FieldNames.financialStatementStocks]             : [stocksInitialValue],
  [FieldNames.financialStatementRetirementAccounts] : [retirementAccountsInitialValue],
  [FieldNames.financialStatementNotes]              : [notesInitialValue],
  [FieldNames.financialStatementPersonalProperties] : [personalPropertyInitialValue],
  [FieldNames.financialStatementRealEstate]         : [realEstateInitialValue],
  [FieldNames.financialStatementLifeInsurance]      : [lifeInsuranceInitialValue],
  [FieldNames.financialStatementCreditCards]        : [creditCardsInitialValue],
  [FieldNames.financialStatementMortgages]          : [mortgagesInitialValue],
  [FieldNames.financialStatementLoans]              : [loansInitialValue],
  [FieldNames.financialStatementTaxes]              : [taxesInitialValue],
};

/**
 * Initial values for commercial and commercial renew form.
 */
export const commercialInitialValues = {
};

export const commercialCollateralInitialValue = {
  [FieldNames.commercialCollateralDescription]  : '',
  [FieldNames.commercialCollateralValue]  : 0,
};

export const ownerExtendedInitialValue = {
  [FieldNames.ownerFirstName]            : '',
  [FieldNames.ownerMiddleName]           : '',
  [FieldNames.ownerLastName]             : '',
  [FieldNames.ownerSuffixName]           : '',
  [FieldNames.ownerStreet]               : '',
  [FieldNames.ownerStreet2]              : '',
  [FieldNames.ownerCity]                 : '',
  [FieldNames.ownerState]                : '',
  [FieldNames.ownerZip]                  : '',
  [FieldNames.ownerPhone]                : '',
  [FieldNames.ownerPersonalNetWorth]     : '',
  [FieldNames.ownerAnnualPersonalIncome] : '',
  [FieldNames.ownerPercentage]           : '',
  [FieldNames.ownerSinceYear]            : '',
  [FieldNames.ownerIsApplicant]          : false,
};

export const eidlInitialValue = {
  [FieldNames.eidlBankName]   : '',
  [FieldNames.eidlLoanType]   : '',
  [FieldNames.eidlCollateral] : '',
  [FieldNames.eidlBalance]    : 0,
};

/**
 * Initial values for small business line of credit form.
 */
export const sbLineOfCreditInitialValues = {
  [FieldNames.commercialOwnerList]          : [ownerExtendedInitialValue],
  [FieldNames.commercialCollateralList]     : [commercialCollateralInitialValue],
  [FieldNames.commercialSameMailingAddress] : YesOrNo.YES,
  [FieldNames.economicInjuryDisasterLoans]  : [eidlInitialValue],
};

/**
 * Initial values for small business term form.
 */
export const sbTermInitialValues = {
  [FieldNames.commercialOwnerList]          : [ownerExtendedInitialValue],
  [FieldNames.commercialCollateralList]     : [commercialCollateralInitialValue],
  [FieldNames.commercialSameMailingAddress] : YesOrNo.YES,
  [FieldNames.economicInjuryDisasterLoans]  : [eidlInitialValue],
};

/**
 * Initial values for refinance turbo form
 */
export const refinanceTurboInitialValues = {
  [FieldNames.decBorrower]      : declarationInitialValue,
  [FieldNames.decCoBorrower]    : declarationInitialValue,
  // Required for typing
  [FieldNames.coBorrowerOnTitle]: YesOrNo.NO,
  [FieldNames.loanAmount]       : 0,
  [FieldNames.numOfDependent]   : 0,
  [FieldNames.livingRentOrOwn]  : LivingSituation.OWN,
};
/**
 * Initial values for purchase turbo form
 */
export const purchaseTurboInitialValues = {
  [FieldNames.assets]           : [assetInitialValue],
  [FieldNames.decBorrower]      : declarationInitialValue,
  [FieldNames.decCoBorrower]    : declarationInitialValue,

  [FieldNames.downDollar]       : 0,
  [FieldNames.downPercent]      : 0,
  [FieldNames.listingPrice]     : 0,
  [FieldNames.loanPurpose]      : LoanPurpose.PURCHASE,
  [FieldNames.yearTaxes]        : 0,
  [FieldNames.yearInsure]       : 0,
  [FieldNames.yearAssociate]    : 0,

  // Required for typing
  [FieldNames.loanAmount]       : 0,
  [FieldNames.numOfDependent]   : 0,
};
